@import url(./inter.less);

html {
  height: 100%;
}

body {
  font-family: Inter;
  margin: 0;
  height: 100%;
}

#root {
  height: 100%;
}

.ant-app {
  height: 100%;
}

/* Scroll bar stylings */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(219, 218, 218);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaaaaa;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

@hack: true; @import "~@/less/index.less";